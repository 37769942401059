<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <div class="title">Cargar documento</div>
      <div class="body">

        <div class="row form-group">
          <label for="seleccionar_archivo" class="col-form-label col-sm-3">Documento</label>
          <div class="col-sm-9">
            <div class="input-group">
              <div class="form-control text-truncate" ref="nombre_archivo"></div>
              <div class="input-group-append">
                <button class="btn btn-secondary" @click="$refs.seleccionar_archivo.click()">Seleccionar archivo</button>
              </div>
            </div>
            <input type="file" name="seleccionar_archivo" id="seleccionar_archivo" ref="seleccionar_archivo" @change="actualizar_nombre">
          </div>
        </div>

        <div class="row">
          <label for="tipo_archivo" class="col-form-label col-sm-3">Tipo de documento</label>
          <div class="col-sm-9">
            <select v-model="archivo.tipo" name="tipo_archivo" id="tipo_archivo" class="form-control">
              <option v-for="opcion in catalogo.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agregar_archivo">Agregar documento</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  
  import apiValija from '@/apps/formalizacion/api/v2/valija'

  export default {
    components: {
      Modal
    }
    ,data() {
      return {
        catalogo: {
          opciones: []
        }
        ,archivo: {
          archivo: null
          ,tipo: null
        }
      }
    }
    ,mounted() {
      this.obtener_catalogo();
    }
    ,methods: {
      async obtener_catalogo() {
        try {
          this.catalogo = await this.$helper.cache_store('valija.tipos_archivos',async () => {
            return (await apiValija.obtener_catalogo('tipos_archivos')).data;
          })

          // this.$log.info('catalogo', this.catalogo);

          this.archivo.tipo = this.catalogo.opciones[0].valor;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,agregar_archivo() {
        if (this.$refs.seleccionar_archivo.files.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar un archivo','error','alert');

        this.archivo.archivo = this.$refs.seleccionar_archivo.files[0]

        this.$emit('file',this.archivo);
      }
      ,actualizar_nombre() {
        if (this.$refs.seleccionar_archivo.files.length == 0)
          return;

        this.$refs.nombre_archivo.innerHTML = this.$refs.seleccionar_archivo.files[0].name;
      }
    }
  }
</script>

<style lang="scss">
  #seleccionar_archivo {
    display: none;
  }
</style>